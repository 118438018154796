// This file is a spin off from application.js except it doesn't include all the legacy CSS from "../css/application.css";
// instead it includes "../css/participant_dashboard.css" to load IBM Plex
import "bootstrap";
import "../css/participant_dashboard.css";

// polyfill for legacy modules depending on "global"
// https://github.com/bevacqua/dragula/issues/602#issuecomment-477734315
if (typeof window.global === "undefined") {
  window.global = window;
}
